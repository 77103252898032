import Conditions from "@/services/marketing-services/Trackings/Conditions";

describe("Conditions", () => {
  describe("isRNTQualified", () => {
    it("should return false if isNewOrRevived is false", () => {
      //given
      const RNTLead = {
        isNewOrRevived: false,
        isTargetZone: true,
        isTestEmail: true,
      };
      // when
      const result = Conditions.isRNTQualified(RNTLead);
      // then
      expect(result).toBeFalsy();
    });

    it("should return false if isTargetZone is false", () => {
      //given
      const RNTLead = {
        isNewOrRevived: true,
        isTargetZone: false,
        isTestEmail: true,
      };
      // when
      const result = Conditions.isRNTQualified(RNTLead);
      // then
      expect(result).toBeFalsy();
    });

    it("should return false if isTestEmail is true", () => {
      //given
      const RNTLead = {
        isNewOrRevived: true,
        isTargetZone: true,
        isTestEmail: true,
      };
      // when
      const result = Conditions.isRNTQualified(RNTLead);
      // then
      expect(result).toBeFalsy();
    });

    it("should return true if all conditions fail", () => {
      //given
      const RNTLead = {
        isNewOrRevived: true,
        isTargetZone: true,
        isTestEmail: false,
      };
      // when
      const result = Conditions.isRNTQualified(RNTLead);
      // then
      expect(result).toBeTruthy();
    });
  });
});
